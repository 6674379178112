import { useAxiosPost } from "./api.js";
import { playSendSound } from './sounds.composeable.js'

export const useChat = async () =>{

    const store = useChatStore()
    const config = useRuntimeConfig();
    const projectStore = useProjectStore()
    const errorStore = useErrorStore()
    const userStore = useUserStore()
    const { subscribeToChannels,unsubscribeTochannels } = await usePusher()

    const getUnreadMessages = async () => {
        try {
            const {data:messages} = await useAxiosGet('chat/unread-messages')
            for (let i = 0; i < messages.length; i++) {
                await store.addMessage(messages[i])
            }
            return messages
        } catch (error) {
            // errorStore.showError(error.message,true,'error')
        }
    }

    const readMessages = async () => {
        try{
            const conversationId = store.current_conversation.id
            const arr = store.unread_messages[conversationId]
            if(arr){
                const {data:messages} = await useAxiosPost(`chat/read-messages`,{messages:arr})
            }
            return 
            
        }catch(error){
            errorStore.showError(error.message,true,'error')
        }
    }

    const readMessage = async (messageId)=>{
        try{
            if(messageId){
                const {data:messages} = await useAxiosPost(`chat/read-messages`,{messages:[messageId]})
            }
            return 
            
        }catch(error){
            errorStore.showError(error.message,true,'error')
        }
    }

    const getChannelsAndSubscribe = async () => {
        try {
            const {data:channels} = await useAxiosGet('chat/channels')
            store.setChannels(channels)
            await subscribeToChannels(channels)
            return channels
        } catch (error) {
            errorStore.showError(error.message,true,'error')
        }
    }

    const unSubscribeChannels = async () => {
        try {
            const {data:channels} = await useAxiosGet('chat/channels')
            await unsubscribeTochannels(channels)
            return
        } catch (error) {
            errorStore.showError(error.message,true,'error')
        }
    }

    const getList = async () => {
        try {
            const {data:list,message} = await useAxiosGet('chat/list')
            store.setList(list)
            return list
        } catch (error) {
            console.log("Dashboard Error : ",error)
            errorStore.showError(error.message,true,'error')
        }
    }

    const getMessages = async (conversationId) => {
        try {
            const conversationId = store.current_conversation.id
            const page = store.message_page + 1
            if((store.messages.length <= 0) || (store.messages.length != store.message_count)){
                const {data:messagesList,message} = await useAxiosGet(`chat/get-messages/${conversationId}?page=${page}`)
                if(messagesList.messages.length > 0){
                    store.setMessagePage(messagesList.page)
                    store.setMessages(messagesList.messages)
                    store.setMessagesTotalCount(messagesList.total)
                    return messagesList.messages
                }
            }
            return []
        } catch (error) {
            console.log("Dashboard Error : ",error)
            errorStore.showError(error.message,true,'error')
        }
    }

    const sendMessage = async (conversationId,data) =>{
        try {
            const {data:message} = await useAxiosFormDataPost('chat/send-message',data)
            playSendSound()
            return message
        } catch (error) {
            console.log("Dashboard Error : ",error)
            errorStore.showError(error.message,true,'error')
        }
    }

    const deleteMessage = async (messageId) => {
       try {
        const conversationId = store.current_conversation.id
         await useAxiosDelete(`chat/delete-message/${conversationId}/${messageId}`)
        //  store.deleteMessage(conversationId,messageId)
       } catch (error) {
        console.log("Dashboard Error : ",error)
        errorStore.showError(error.message,true,'error')
       }
    }

    const getDownloadLink = async (bucket,key) =>{
        const url = await useAxiosPost('download',{
            bucket:bucket,
            key:key
        })
        return url
    }

    // Group

    const createGroup = async (group_name) => {
        try {
            const {data:group,message} = await useAxiosPost('chat/group',{
                title:group_name
            })
            group['conversation_id'] = group.id
            // store.addGroup(group)
            errorStore.showError(message,true,'success')
            return group
        } catch (error) {
            console.log('GRoup Error : ',error)
            errorStore.showError(error.message,true,'error')
        }
    }

    const deleteGroup = async (group_id) => {
        try {
            const {data:group,message} = await useAxiosDelete(`chat/group/${group_id}`)
            store.deleteGroup(group_id)
            errorStore.showError(message,true,'success')
            return group
        } catch (error) {
            console.log('GRoup Error : ',error)
            errorStore.showError(error.message,true,'error')
        }
    }

    const updateGroup = async (group_id,data) => {
        try {
            const {data:group,message} = await useAxiosPatch(`chat/group/${group_id}`,{
               ...data
            })
            store.updateGroupDetail(group_id,group)
            errorStore.showError(message,true,'success')
            return group
        } catch (error) {
            console.log('GRoup Error : ',error)
            errorStore.showError(error.message,true,'error')
        }
    }

    const addUserToGroup = async (group_id,user_id) => {
        try {
            const {data:user,message} = await useAxiosPost(`chat/group/${group_id}/add-user`,{user_id:user_id})
            errorStore.showError('User added successfully',false,'success')
            return user
        } catch (error) {
            console.log('GRoup Error : ',error)
            errorStore.showError(error.message,true,'error')
        }
    }

    const removeUserFromGroup = async (group_id,user_id) => {
        try {
            const {data:user,message} = await useAxiosPost(`chat/group/${group_id}/remove-user`,{user_id:user_id})
            errorStore.showError('User added successfully',false,'success')
            return user
        } catch (error) {
            console.log('GRoup Error : ',error)
            errorStore.showError(error.message,true,'error')
        }
    }

    const getGroupUsers = async(group_id) => {
        try {
            const {data:users,message} = await useAxiosGet(`chat/group/${group_id}/users`)
            errorStore.showError(message,false,'success')
            return users
        } catch (error) {
            console.log('GRoup Error : ',error)
            errorStore.showError(error.message,true,'error')
        }
    }

    const changeGroupIcon = async(groupID,formData) => {
        try {
            const {data:res,message} = await useAxiosFormDataPost(`upload?bucket="chat"`,formData)
            await updateGroup(groupID,{profile:res.key})
            store.updateProfile(groupID,res.key)
            errorStore.showError(message,true,'success')
            return 
        } catch (error) {
            console.log('Auth Error : ',error)
            errorStore.showError(error.message,true,'error')
        }
    }

    // Meeting

    const getMeetingDetail = async(eventId)=>{
        try {
            const {data:meeting,message} = await useAxiosGet(`chat/meeting/${eventId}`)
            store.setMeeting(meeting)
            return meeting
        } catch (error) {
            console.log('getMeetingDetail : ',error)
            errorStore.showError(error.message,true,'error')
        }
    }

    const getMeetings = async (conversationId) => {
        try {
            const {data:meeting,message} = await useAxiosGet('chat/meeting')
            store.setMeetings(meeting)
            return meeting
        } catch (error) {
            console.log('GRoup Error : ',error)
            errorStore.showError(error.message,true,'error')
        }
    }

    const getMeetingsByMonth = async (currentMonth) => {
        try {
            const {data:meeting,message} = await useAxiosGet(`chat/meetings?date=${currentMonth}`)
            return meeting
        } catch (error) {
            console.log('GRoup Error : ',error)
            errorStore.showError(error.message,true,'error')
        }
    }

    const createMeeting = async (meetingDetail,conversation_id=null) =>{
        try {
            let conversationId = conversation_id != null ? conversation_id : store.current_conversation.id
            const {data:meeting,message} = await useAxiosPost('chat/meeting',{...meetingDetail,conversation_id:conversationId})
            if(meeting.url){
                userStore.removeGoogleToken()
                window.open(meeting.url,'_blank')
                return
            }
            errorStore.showError(message,true,'success')
            return meeting
        } catch (error) {
            console.log('GRoup Error : ',error)
            errorStore.showError(error.message,true,'error')
        }
    }

    const deleteMeeting = async (meetingId) =>{
        try {
            const {data:meeting,message} = await useAxiosDelete(`chat/meeting/${meetingId}`)
            // store.deleteMeeting(meetingId)
            errorStore.showError(message,true,'success')
            return 
        } catch (error) {
            console.log('GRoup Error : ',error)
            errorStore.showError(error.message,true,'error')
        }
    }

    const deleteEvent = async(eventId)=>{
        try {
            const {data:meeting,message} = await useAxiosDelete(`chat/event/${eventId}`)
            errorStore.showError(message,true,'success')
            return 
        } catch (error) {
            console.log('deleteEvent Error : ',error)
            errorStore.showError(error.message,true,'error')
        }
    }

    const editEventFromCalendar = async(eventId,meetingDetail)=>{
        try {
            const {data:meeting,message} = await useAxiosPatch(`chat/event/${eventId}`,meetingDetail)
            if(meeting.url){
                window.open(meeting.url,'_blank')
                return
            }
            errorStore.showError(message,true,'success')
            return meeting
        } catch (error) {
            console.log('GRoup Error : ',error)
            errorStore.showError(error.message,true,'error')
        }
    }

    const updateMeeting =async (messageId,meetingId,meetingData) => {
        try {
            const conversationId = store.current_conversation.id
            const {data:meeting,message} = await useAxiosPatch(`chat/${messageId}/meeting/${meetingId}`,{
                conversation_id:conversationId,
                ...meetingData
            })

            // store.updateMeeting(messageId,meeting)
            errorStore.showError(message,true,'success')
            return 
        } catch (error) {
            console.log('GRoup Error : ',error)
            errorStore.showError(error.message,true,'error')
        }
    }

    const cancelMeeting = async (messageId,meetingId)=>{
        try {
            const {data:meeting,message} = await useAxiosPatch(`chat/${messageId}/meeting/${meetingId}/cancel`,{})
            // store.updateMeeting(messageId,meeting)
            errorStore.showError(message,true,'success')
            return 
        } catch (error) {
            console.log('GRoup Error : ',error)
            errorStore.showError(error.message,true,'error')
        }
    }

    const getConversationUserList = async () => {
        try {const userMap = new Map();
            projectStore.associates.forEach(u => {
                userMap.set(u.email,u)
            });
            const {data:users,message} = await useAxiosGet('chat/list-users')
            const updatedList = users.map(u =>{
                let a = userMap.has(u.email)
                if(a) return u
            })
            console.log(users,"getConversationUserList")
            return updatedList
            // const {data:users,message} = await useAxiosGet('chat/list-users')
            // console.log(users,"getConversationUserList")
            // return users
        } catch (error) {
            console.log('getConversationUserList: ',error)
            errorStore.showError(error.message,true,'error')
        }
    }

    return { 
        getList,
        getMessages,
        sendMessage,
        deleteMessage,
        getDownloadLink,
        createGroup,
        deleteGroup,
        updateGroup,
        addUserToGroup,
        getChannelsAndSubscribe,
        unSubscribeChannels,
        getMeetings,
        createMeeting,
        deleteMeeting,
        updateMeeting,
        readMessage,
        readMessages,
        getUnreadMessages,
        getGroupUsers,
        removeUserFromGroup,
        cancelMeeting,
        changeGroupIcon,
        getMeetingsByMonth,
        getConversationUserList,
        getMeetingDetail,
        editEventFromCalendar,
        deleteEvent
    }
}